<template>
	<div class="c-base-input-dropdown">
		<div
			:class="[
				'w-full absolute top-0 left-0',
				'overflow-hidden rounded-26 >=1024:rounded-32',
				'duration-500 ease-smooth-out',
				'border-2 border-text bg-text',
			]"
			:style="`height: ${height}px`"
			@focusout="onFocusout"
		>
			<DropdownButton
				:class="[
					'w-full h-48 >=1024:h-60 px-lg',
					'text-left text-white',
					'font-darker-grotesque font-semibold text-input-dropdown',
					'flex items-center justify-between bg-text',
					'relative z-20',
				]"
				:aria-owns="id"
			>
				<Transition name="t-dropdown-button" mode="out-in">
					<div
						:key="value"
						:class="[
							'mb-2xs py-4 whitespace-nowrap',
							'overflow-hidden overflow-ellipsis',
						]"
						v-text="label"
					></div>
				</Transition>

				<SvgCaret
					:class="[
						'flex-shrink-0 ml-8',
						'transform w-16 text-white',
						'duration-500 ease-smooth-out',
						{ 'mb-2 -rotate-180': isExpanded },
					]"
				/>
			</DropdownButton>

			<DropdownOptionList
				ref="list"
				:class="[
					'absolute w-full bottom-0 left-0 py-md',
					'max-h-480 overflow-y-scroll bg-white',
				]"
				v-bind="{ id, value, transition: { name: 't-dropdown-list' } }"
				@expand="onExpand"
				@input="onInput"
			>
				<DropdownOption
					v-for="option in options"
					:id="option.value"
					:key="option.value"
					:class="[
						'font-darker-grotesque font-semibold text-label-card',
						'min-w-full text-opacity-70 text-text hover:bg-background',
						'px-lg pt-3 pb-8 break-words',

						{
							'text-opacity-70 hover:bg-background': !simple,
							'font-semibold bg-background text-opacity-100':
								option.value === value && !simple,
						},
					]"
					:aria-selected="option.value === value"
				>
					<slot name="option" v-bind="option">
						<span v-text="option.label"></span>
					</slot>
				</DropdownOption>
			</DropdownOptionList>
		</div>
	</div>
</template>

<script>
import {
	DropdownButton,
	DropdownOptionList,
	DropdownOption,
} from '~/citi-baseline/components/Dropdown';
import SvgCaret from '~/assets/svgs/icon-caret.svg?inline';

export default {
	name: 'BaseInputDropdown',

	components: {
		DropdownButton,
		DropdownOptionList,
		DropdownOption,
		SvgCaret,
	},

	props: {
		placeholder: String,
		value: String,

		id: {
			type: String,
			required: true,
		},

		options: {
			type: Array,
			default: () => [],
			validator: (value) =>
				value.reduce(
					(acc, cur) => acc && cur.label != null && cur.value != null,
					true
				),
		},

		simple: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			isExpanded: false,
			height: null,
		};
	},

	computed: {
		label() {
			return (
				this.options.find((option) => option.value === this.value)
					?.label ?? this.placeholder
			);
		},

		collapsedHeight() {
			const { height } = this.$el?.getBoundingClientRect();
			return height;
		},
	},

	mounted() {
		this.updateHeight(false);
		window.addEventListener('resize', () =>
			this.updateHeight(this.isExpanded)
		);
	},

	beforeDestroy() {
		window.removeEventListener('resize', () =>
			this.updateHeight(this.isExpanded)
		);
	},

	methods: {
		toggle(forced) {
			if (this.$refs.list) {
				this.$refs.list.toggleList(forced);
			}
		},

		updateHeight(expanded) {
			if (expanded) {
				const { list } = this.$refs;
				const outerHeight = this.$el?.getBoundingClientRect()?.height;
				const innerHeight = list?.$el?.getBoundingClientRect()?.height;

				return (this.height = Math.min(
					outerHeight + innerHeight,
					480 + outerHeight
				));
			}

			const { height } = this.$el?.getBoundingClientRect();
			this.height = height;
		},

		/** Events */
		onInput(id) {
			this.$emit('input', id);
		},

		onExpand(value) {
			this.$emit('expand', value);

			this.$nextTick(() => {
				this.isExpanded = value;
				this.updateHeight(value);
			});
		},

		onFocusout(e) {
			if (!e.relatedTarget || !this.$el.contains(e.relatedTarget)) {
				this.toggle(false);
			}
		},
	},
};
</script>

<style lang="postcss">
.c-base-input-dropdown {
	@apply relative h-52;
}

@screen >=1024 {
	.c-base-input-dropdown {
		@apply h-64;
	}
}

.t-dropdown-list-enter-active,
.t-dropdown-list-leave-active,
.t-dropdown-list-enter-active > *,
.t-dropdown-list-leave-active > * {
	@apply transition-opacity duration-500 ease-smooth-out;
}

.t-dropdown-list-enter > *,
.t-dropdown-list-leave-to > * {
	@apply opacity-0;
}

.t-dropdown-button-enter-active,
.t-dropdown-button-leave-active {
	@apply transition-transform transform duration-250 ease-smooth-out;
}

.t-dropdown-button-enter {
	@apply -translate-y-8;
}
.t-dropdown-button-leave-to {
	@apply translate-y-8;
}
</style>
