// Last modified: 2022/06/07 16:08:08
import Vue from 'vue';

import DropdownButton from './DropdownButton';
import DropdownOption from './DropdownOption';
import DropdownOptionList from './DropdownOptionList';

const _dropdownLists = Vue.observable({});
const _dropdownListStates = Vue.observable({});

export {
	_dropdownLists,
	_dropdownListStates,
	DropdownButton,
	DropdownOption,
	DropdownOptionList,
};
