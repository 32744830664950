var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-base-input-dropdown"},[_c('div',{class:[
			'w-full absolute top-0 left-0',
			'overflow-hidden rounded-26 >=1024:rounded-32',
			'duration-500 ease-smooth-out',
			'border-2 border-text bg-text',
		],style:(`height: ${_vm.height}px`),on:{"focusout":_vm.onFocusout}},[_c('DropdownButton',{class:[
				'w-full h-48 >=1024:h-60 px-lg',
				'text-left text-white',
				'font-darker-grotesque font-semibold text-input-dropdown',
				'flex items-center justify-between bg-text',
				'relative z-20',
			],attrs:{"aria-owns":_vm.id}},[_c('Transition',{attrs:{"name":"t-dropdown-button","mode":"out-in"}},[_c('div',{key:_vm.value,class:[
						'mb-2xs py-4 whitespace-nowrap',
						'overflow-hidden overflow-ellipsis',
					],domProps:{"textContent":_vm._s(_vm.label)}})]),_vm._v(" "),_c('SvgCaret',{class:[
					'flex-shrink-0 ml-8',
					'transform w-16 text-white',
					'duration-500 ease-smooth-out',
					{ 'mb-2 -rotate-180': _vm.isExpanded },
				]})],1),_vm._v(" "),_c('DropdownOptionList',_vm._b({ref:"list",class:[
				'absolute w-full bottom-0 left-0 py-md',
				'max-h-480 overflow-y-scroll bg-white',
			],on:{"expand":_vm.onExpand,"input":_vm.onInput}},'DropdownOptionList',{ id: _vm.id, value: _vm.value, transition: { name: 't-dropdown-list' } },false),_vm._l((_vm.options),function(option){return _c('DropdownOption',{key:option.value,class:[
					'font-darker-grotesque font-semibold text-label-card',
					'min-w-full text-opacity-70 text-text hover:bg-background',
					'px-lg pt-3 pb-8 break-words',

					{
						'text-opacity-70 hover:bg-background': !_vm.simple,
						'font-semibold bg-background text-opacity-100':
							option.value === _vm.value && !_vm.simple,
					},
				],attrs:{"id":option.value,"aria-selected":option.value === _vm.value}},[_vm._t("option",function(){return [_c('span',{domProps:{"textContent":_vm._s(option.label)}})]},null,option)],2)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }