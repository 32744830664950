<!--
	Last modified: 2023/09/08 14:58:18
-->
<template>
	<button
		aria-haspopup="listbox"
		class="c-dropdown-button"
		v-bind="attrs"
		v-on="$listeners"
		@keydown.tab="() => toggleRelatedLists(false)"
		@keydown.esc.prevent="() => toggleRelatedLists(false)"
		@keydown.down.prevent="() => toggleRelatedLists(true, true)"
		@keydown.space.prevent="() => toggleRelatedLists()"
		@keydown.enter.prevent="() => toggleRelatedLists()"
		@click.stop="() => toggleRelatedLists()"
	>
		<slot v-bind="{ isExpanded }"></slot>
	</button>
</template>

<script>
import { _dropdownLists, _dropdownListStates } from './index.js';

export default {
	name: 'DropdownButton',
	inheritAttrs: false,

	props: {
		ariaOwns: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			isExpanded: false,
			activeOption: null,
		};
	},

	computed: {
		attrs() {
			return {
				'aria-owns': this.ariaOwns,
				'aria-controls': this.ariaOwns,
				'aria-expanded': this.isExpanded?.toString(),
				'aria-activedescendant': this.isExpanded
					? this.activeOption
					: null,
				...this.$attrs,
			};
		},

		lists() {
			const listIds = this.ariaOwns.split(' ');
			return Object.values(_dropdownLists).filter(({ id }) =>
				listIds.includes(id)
			);
		},
	},

	mounted() {
		this.$watch(() => _dropdownListStates, this.onListChange, {
			immediate: true,
			deep: true,
		});
		window.addEventListener('keyup', this.anyKeyUp);
	},
	beforeDestroy() {
		window.removeEventListener('keyup', this.anyKeyUp);
	},

	methods: {
		toggleRelatedLists(forced, focus) {
			this.lists.forEach((list) => {
				list.toggleList?.(forced ?? !this.isExpanded);
			});

			if ((forced ?? !this.isExpanded) && focus) {
				// todo: handle the possibility of multiple lists
				this.lists[0]?.$el.focus();
			}
		},

		onListChange(value) {
			const listIds = this.ariaOwns.split(' ');
			this.isExpanded = Object.entries(value)
				.filter(([key, _]) => listIds.includes(key))
				.reduce((acc, [_, cur]) => acc || cur, false);
		},

		anyKeyUp() {
			if (this.isExpanded) {
				this.$nextTick(() => {
					this.activeOption = this.lists?.[0]?.activeOption;
				});
			}
		},
	},
};
</script>
