<!--
	Last modified: 2023/09/08 14:54:21
-->
<template>
	<Transition v-bind="transition">
		<ul
			v-show="isExpanded"
			class="c-dropdown-option-list"
			role="listbox"
			v-bind="attrs"
			v-on="$listeners"
			@click.stop
			@keydown.tab.prevent="() => toggleList(false)"
			@keydown.esc.prevent="() => toggleList(false)"
			@keydown.up.prevent.capture="() => setHoverIndex(-1)"
			@keydown.down.prevent.capture="() => setHoverIndex(+1)"
			@focus="onFocus"
			@blur="onBlur"
		>
			<slot></slot>
		</ul>
	</Transition>
</template>

<script>
import { _dropdownLists, _dropdownListStates } from './index.js';

export default {
	name: 'DropdownOptionList',

	provide() {
		return {
			setSelectedOption: this.setSelectedOption,
			toggleOptionList: this.toggleList,
		};
	},

	inheritAttrs: false,

	props: {
		id: String,
		value: String,
		ariaLabel: String,
		transition: Object,
	},

	data() {
		return {
			activeOption: null,
			isExpanded: false,
			hoverIndex: -1,
		};
	},

	computed: {
		attrs() {
			return {
				id: this.id,
				tabIndex: '-1',
				'aria-label': this.ariaLabel,
				...this.$attrs,
			};
		},
	},

	watch: {
		hoverIndex(value) {
			if (value > -1) {
				this.$children[value]?.$el?.focus();
				this.activeOption = this.$children[value]?.id;
			}
		},
		isExpanded(value) {
			this.$emit('expand', value);
		},
	},

	created() {
		if (this.id != null) {
			this.$set(_dropdownLists, this.id, this);
			this.$set(_dropdownListStates, this.id, this.isExpanded);
		}
	},

	mounted() {
		window.addEventListener('click', this.toggleList.bind(this, false));
	},

	beforeDestroy() {
		window.removeEventListener('click', this.toggleList.bind(this, false));

		if (this.id != null) {
			delete _dropdownLists[this.id];
		}
	},

	methods: {
		toggleList(forced) {
			this.isExpanded = forced != null ? forced : !this.isExpanded;
			this.$set(_dropdownListStates, this.id, this.isExpanded);
			this.hoverIndex = -1;
		},

		setHoverIndex(delta) {
			// todo: handle the possibility of multiple lists
			this.hoverIndex += delta;
			this.hoverIndex %= this.$children.length;

			if (this.hoverIndex < 0) {
				this.hoverIndex = this.$children.length - 1;
			}
		},

		setSelectedOption(id) {
			this.activeOption = id;
			this.$emit('input', id);
		},

		/** Events */
		onFocus() {
			if (this.hoverIndex < 0) {
				this.hoverIndex = 0;
			}
		},

		onBlur() {
			this.hoverIndex = -1;
		},
	},
};
</script>
