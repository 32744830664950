<!--
	Last modified: 2023/09/08 15:21:00
-->
<template>
	<li
		class="c-dropdown-option"
		role="option"
		v-bind="attrs"
		v-on="$listeners"
		@click="setSelected"
		@keydown.space.prevent="setSelected"
		@keydown.enter.prevent="setSelected"
	>
		<slot></slot>
	</li>
</template>

<script>
export default {
	name: 'DropdownOption',

	inject: {
		setSelectedOption: {
			default: () => {},
		},
		toggleOptionList: {
			default: () => {},
		},
	},
	inheritAttrs: false,

	props: {
		id: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			isSelected: false,
		};
	},

	computed: {
		attrs() {
			return {
				id: this.id || null,
				tabIndex: '-1',
				...this.$attrs,
			};
		},
	},

	methods: {
		setSelected() {
			this.setSelectedOption(this.id);
			this.toggleOptionList(false);
		},
	},
};
</script>

<style lang="postcss">
:where(.c-dropdown-option) {
	@apply cursor-pointer max-w-fit;
}
</style>
